import { useState } from 'react';
import { useToast } from '@context/ToastContext';
import { InputText } from '@components/forms';
import Buttom from '@components/buttons';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { signUp } from '@hooks/useAuth';

const LoginForm = () => {
  const { showToast } = useToast();

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [rol, setRol] = useState('');
  const [location, setLocation] = useState('');
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onAuth = () => {
    const data = {
      name,
      email,
      phone,
      company,
      rol,
      location,
      document,
      password,
    };

    signUp(data)
      .then(() => {
        showToast('¡Felicidades! Has iniciado sesión con éxito.', 'success');
        navigate('/');
      })
      .catch(() => {
        showToast('Ha ocurrido un error.', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="login-containers" id="sign-up-container">
      {loading && <div className="loadingAuth">laoding</div>}

      <br />
      <br />

      <h2>Nombre y Apellido</h2>
      <InputText value={name} onChange={setName} />

      <h2>Ingresa tu cédula</h2>
      <InputText value={document} onChange={setDocument} />

      <h2>Correo</h2>
      <InputText type="email" value={email} onChange={setEmail} />

      <h2>Teléfono</h2>
      <InputText value={phone} onChange={setPhone} />

      <h2>Empresa</h2>
      <InputText value={company} onChange={setCompany} />

      <h2>Cargo</h2>
      <InputText value={rol} onChange={setRol} />

      <h2>Ciudad</h2>
      <InputText value={location} onChange={setLocation} />

      <h2>Ingresa tu clave</h2>
      <InputText value={password} onChange={setPassword} />

      <br />

      <br />
      <Buttom size="lg" label="Ingresar" onClick={onAuth} />
    </div>
  );
};

export default LoginForm;
