import React from 'react';
import BottomNav from '@components/bottomNav';

import './style.css';
import { Link } from 'react-router-dom';

type Props = {
  title?: string;
  children: React.ReactNode;
  icon?: boolean;
  showBottomNav?: boolean;
  showNavMobile?: boolean;
  showNotification?: boolean;
  showGoBack?: boolean;
};

const Layout: React.FC<Props> = ({
  title = '',
  children,
  icon = true,
  showBottomNav = false,
  showNavMobile = true,
  showNotification = true,
  showGoBack = true,
}) => {
  return (
    <div className="App">
      <div className="child-component">
        <img
          id="isotipo-layout"
          src={require('@assets/logos/isotipo.png')}
          alt=""
        />
        {children}
      </div>

      {showBottomNav && <BottomNav />}
      {showBottomNav && (
        <div className="buttom-calendar">
          <Link to={'/speed/dating/shedulle/appointments'}>
            <i className="fas fa-calendar"></i>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Layout;
