import { useEffect, useState } from 'react';
import requests from 'helpers/requests';
import { useToast } from '@context/ToastContext';

export const useSlots = () => {
  const { showToast } = useToast();
  const [data, setData] = useState<any>([]);
  const [invitations, setinvitations] = useState<any>([]);
  const [scheduledAppointments, setScheduledAppointments] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const getInitData = async () => {
    try {
      setLoading(true);
      const response = await requests.get('slots');
      setData(response.data.available_slots);
      setLoading(false);
      return response.data;
    } catch (error: any) {
      setLoading(false);
      console.log(error, 'error init');
      console.error('Error verifying authentication:', error);
    }
  };

  const reserve = async (data: any) => {
    try {
      setLoading(true);
      const response = await requests.post('reserve', data);
      getInitData();
      showToast('Se a enviado la invitacion', 'success');
      return response.data;
    } catch (error: any) {
      setLoading(false);
      showToast(error.response.data.message, 'error');
      console.log(error, 'error init');
      console.error('Error verifying authentication:', error);
    }
  };

  const getinvitations = async () => {
    try {
      setLoading(true);
      const response = await requests.post('invitations', data);
      setinvitations(response.data);
      return response.data;
    } catch (error: any) {
      setLoading(false);
      console.error('Error verifying authentication:', error);
    }
  };

  const acceptInvitation = async (data: any) => {
    try {
      await requests.post('accept/invitations', data);
      showToast('Invitación aceptada', 'success');
      getinvitations();
    } catch (error: any) {
      setLoading(false);
      console.error('Error verifying authentication:', error);
    }
  };

  const rejectInvitation = async (data: any) => {
    try {
      await requests.post('reject/invitations', data);
      showToast('Invitación rechazada', 'success');
      getinvitations();
    } catch (error: any) {
      setLoading(false);
      console.error('Error verifying authentication:', error);
    }
  };

  const getScheduledAppointments = async () => {
    try {
      const response = await requests.post('get/schedulled', data);
      setScheduledAppointments(response.data);
    } catch (error: any) {
      setLoading(false);
      console.error('Error verifying authentication:', error);
    }
  };

  useEffect(() => {
    getInitData();
    getinvitations();
    getScheduledAppointments();
  }, []);

  return {
    data,
    loading,
    refresh: getInitData,
    reserve,
    invitations,
    getinvitations,
    acceptInvitation,
    rejectInvitation,
    scheduledAppointments,
    getScheduledAppointments,
  };
};
