import { useSlots } from '@hooks/useSlots';
import './style.css';
import Layout from '@components/layout';

const LoginForm = () => {
  const { scheduledAppointments } = useSlots();

  return (
    <Layout showBottomNav={true}>
      <div id="speed-dating-shedulle-appointments">
        <h2>SpeedDating</h2>
        <br />
        <div className="content-shedulle-appointments">
          <h3>Citas programadas</h3>
        </div>

        <div>
          {scheduledAppointments.map((item: any) => {
            return (
              <div className="invite-card">
                <div className="invite-card-content">
                  <img
                    className="invite-card-image"
                    src={item.user.image}
                    alt={item.user.name}
                  />
                  <div className="invite-card-info">
                    <h3 className="invite-card-name">{item.user.name}</h3>
                    <p>Tienes una cita programada</p>
                    <p>
                      <strong>
                        Hora: {item.reservation.start_time}{' '}
                        {item.reservation.end_time}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="invite-card-actions">
                  <button className="invite-card-reject">Cancelar</button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
