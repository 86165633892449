import './style.css';
import Layout from '@components/layout';
import { useMatches } from '@hooks/useMatches';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  const { data } = useMatches();

  return (
    <Layout showBottomNav={true}>
      <div id="matches">
        <h2>Chats</h2>

        {data.map((item: any) => {
          return (
            <div className="math-item">
              <div>
                <span>{item.name}</span>
              </div>

              <div>
                <a href={`https://wa.me/${item.phone}`} target="_blank">
                  <i className="fab fa-whatsapp whatsapp"></i>
                </a>
                &nbsp; &nbsp;
                <Link to="/directorie/profile" state={{ profile: item }}>
                  {' '}
                  <i className="fas fa-user"></i>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default LoginForm;
