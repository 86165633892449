import { useLocation } from 'react-router-dom';
import './style.css';
import Layout from '@components/layout';
import { useSlots } from '@hooks/useSlots';

const LoginForm = () => {
  const { invitations, acceptInvitation, rejectInvitation } = useSlots();
  const accept = (id: any) => {
    const data = { slot_id: id };
    acceptInvitation(data);
  };
  const reject = (id: any) => {
    const data = { slot_id: id };
    rejectInvitation(data);
  };

  return (
    <Layout showBottomNav={true}>
      <div id="speed-dating-invitation">
        <h2>SpeedDating</h2>
        <br />
        <div className="content-invitations">
          <h3>Invitaciones por aceptar</h3>
        </div>

        <div>
          {invitations.map((item: any) => {
            return (
              <div className="invite-card">
                <div className="invite-card-content">
                  <img
                    className="invite-card-image"
                    src={item.user.image}
                    alt={item.user.name}
                  />
                  <div className="invite-card-info">
                    <h3 className="invite-card-name">{item.user.name}</h3>
                    <p>Te ha invitado a una pola en el SpeedDating</p>
                    <p>
                      <strong>
                        Hora: {item.reservation_slot.start_time} -{' '}
                        {item.reservation_slot.end_time}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="invite-card-actions">
                  <button
                    className="invite-card-accept"
                    onClick={() => accept(item.id)}
                  >
                    Aceptar
                  </button>
                  <button
                    className="invite-card-reject"
                    onClick={() => reject(item.id)}
                  >
                    Rechazar
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
