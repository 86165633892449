import './style.css';
import LogoWhite from '@assets/logos/logo.png';
function App() {
  return (
    <div className="splashContainer">
      <img src={LogoWhite} alt="MiningOptions" className="logoSplash" />
    </div>
  );
}

export default App;
