import LogoWhite from '@assets/logos/logo.png';
import './style.css';
import Buttom from '@components/buttons';
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const navigate = useNavigate();

  return (
    <div className="launch-container">
      <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
      <br />
      <br />
      <p>
        Al hacer clic en "Crear cuenta" o "Iniciar sesión", aceptas nuestros
        Términos. Consulta cómo procesamos tus datos en nuestra Política de
        Privacidad y Política de Cookies.
      </p>
      <br />
      <br />

      <div className="launch-btns">
        <Buttom
          size="lg"
          variant="line"
          label="INGRESAR"
          onClick={() => {
            navigate('/sing-in');
          }}
        />
        <br />
        <Buttom
          size="lg"
          variant="line"
          label="CREAR MI CUENTA"
          onClick={() => {
            navigate('/sing-up');
          }}
        />

        <br />
        <br />
        <p>¿Tienes problemas para ingresar?</p>
      </div>
    </div>
  );
};

export default Index;
